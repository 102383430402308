import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { CarSettings } from '../car/CarSettings';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DISCOUNT_UPDATE } from '@/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { DogSettings } from '../dog/DogSettings';
import store from '@/store/store';
import { getDiscount, handleIncludeDiscountPlusCustomer } from '../services/CalculationService';
import { jaNejArray, ValuePair } from '../services/ValidationService';
import { TravelSettings } from '../travel/TravelSettings';
import { FamilySettings } from '../family/FamilySettings';
import { hasDependentSplashProduct } from '../services/campaignService';

import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { toggleModalSplashCampaign } from '../services/AlertService';

@Options({
	name: 'ExistingAbProductsComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>,
	},
})
export default class ExistingAbProductsComponent extends Vue {
	card: CardBlock; // Card from cms

	public model!: any; // datastructure for user input (store)
	cms!: DogSettings | CarSettings | AccidentSettings | HomeSettings | HouseSettings | TravelSettings | FamilySettings; // settings from CMS (store)
	public plusCustomerInfo = '';

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public discountProductsFiltered: Array<string> = [];
	public updateKeys: Map<string, boolean> = new Map();
	public campaignHasDiscount = false;
	private isSplashCampaignReady = false;

	private previousProducts: string[] = [];

	private unsubscribe: any;

	public async created() {
		const basket = store.getters.getSelectableGroupUnique('basket');
		this.discountProductsFiltered = this.cms.getDiscountProducts(basket);

		this.model.personInfo.existingAlmBrandProducts.forEach((product) => {
			this.updateKeys.set(product, true);
		});
		if (store.state.campaignSplashDependentActive) {
			this.isSplashCampaignReady = hasDependentSplashProduct();
		}
	}

	public beforeMount() {
		if (store.state.campaignSplashDependentActive) {
			this.isSplashCampaignReady = hasDependentSplashProduct();
			this.previousProducts = [...this.model.personInfo.existingAlmBrandProducts];
		}

		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				const basket = store.getters.getSelectableGroupUnique('basket');
				this.discountProductsFiltered = this.cms.getDiscountProducts(basket);
			}
		});
	}

	public beforeUnmount() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}

	public async mounted() {
		if (this.model.campaign?.valid && this.model.campaign?.discount > 0) {
			this.campaignHasDiscount = true;
		}

		// set discount on reentry
		await this.setDiscount();
	}

	public async radioEvent(evt) {
		this.model.personInfo.almbrandCustomer = evt.value;
		if (this.model.personInfo.almbrandCustomer === 'nej') {
			this.model.personInfo.existingAlmBrandProducts = [];
			this.updateKeys.forEach((val, key) => {
				this.updateKeys.set(key, false);
			});
			this.updateKeys.clear();
		} else if (this.discountProductsFiltered.length === 1) {
			this.model.personInfo.existingAlmBrandProducts = [this.cms.discountProductNone];
		}
		this.model.pristineStep.set(this.card.name, true);
		await this.setDiscount();
		this.checkSplashCampaign();
	}

	public async checkboxEvent(selectedProduct) {
		if (selectedProduct.checked) {
			this.model.personInfo.existingAlmBrandProducts.push(selectedProduct.name);
			if (selectedProduct.name === this.cms.discountProductNone) {
				// remove any selected products
				this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
					(product) => product === this.cms.discountProductNone
				);
				this.updateKeys.clear();
				this.updateKeys.set(this.cms.discountProductNone, true);
			} else {
				// remove "none selected products" if selected
				this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
					(product) => product !== this.cms.discountProductNone
				);
				this.updateKeys.set(selectedProduct.name, true);
				this.updateKeys.set(this.cms.discountProductNone, false);
			}
		} else {
			this.model.personInfo.existingAlmBrandProducts = this.model.personInfo.existingAlmBrandProducts.filter(
				(product) => product !== selectedProduct.name
			);
		}
		await this.setDiscount();
		this.checkSplashCampaign();
	}

	public checkSplashCampaign() {
		if (store.state.campaignSplashDependentActive) {
			const isSlashCampaignActive = hasDependentSplashProduct();

			if (this.isSplashCampaignReady && !isSlashCampaignActive) {
				const current = this.model.personInfo.existingAlmBrandProducts;
				let productMissing = 'forsikring';
				this.previousProducts.forEach((product) => {
					if (productMissing === 'forsikring' && !current.includes(product)) {
						// Only show first dependent insurance
						if (store.state.campaignSplashDependentProducts.includes(product.toLowerCase())) {
							productMissing = product;
						}
					}
				});

				toggleModalSplashCampaign(productMissing, this.cms);
			}

			if (!this.isSplashCampaignReady && isSlashCampaignActive) {
				const current = this.model.personInfo.existingAlmBrandProducts;

				let productAdded = 'forsikring';
				if (this.previousProducts.length === 0) {
					productAdded = current[0];
				} else {
					this.previousProducts.forEach((product) => {
						if (!current.includes(product)) {
							productAdded = product;
						}
					});
				}

				toggleModalSplashCampaign(productAdded, this.cms, true);
			}

			this.isSplashCampaignReady = isSlashCampaignActive;
			this.previousProducts = [...this.model.personInfo.existingAlmBrandProducts];
		}
	}

	private async setDiscount() {
		this.model.calculation.discount = 1;
		this.model.calculation.discountDisplay = undefined;
		this.plusCustomerInfo = this.campaignHasDiscount
			? this.cms.plusCustomerNoDiscount
			: this.cms.plusCustomerNoDiscountNoCampaignDiscount;

		const rebate = getDiscount(this.cms, true);

		switch (rebate.discount) {
			case 0.95:
				this.model.calculation.discount = 0.95;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '5%');
				break;
			case 0.9:
				this.model.calculation.discount = 0.9;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '10%');
				break;

			case 0.85:
				this.model.calculation.discount = 0.85;
				this.plusCustomerInfo = this.cms.plusCustomerDiscount.replace('¤discount', '15%');
				break;
			default:
				this.model.calculation.discount = 1;
		}

		this.model.calculation.discountDisplay = rebate.discountDisplay;
		handleIncludeDiscountPlusCustomer(this.model);
		await store.dispatch(DISCOUNT_UPDATE);
	}

	public get customerErr() {
		return !this.model.pristineStep.get(this.card.name) && !this.model.personInfo.almbrandCustomer;
	}

	public get errorProducts() {
		// KLK, er dette ok: model.personInfo.existingAlmBrandProducts.length < 1
		return (
			!this.model.pristineStep.get(this.card.name) &&
			this.model.personInfo.almbrandCustomer === 'ja' &&
			this.model.personInfo.existingAlmBrandProducts.length < 1
		);
	}
}
