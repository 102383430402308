import AxiosService from '@/views/calculators/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { CalculatorUiComponent } from '@/views/calculators/BuyInsuranceComponent';
import { addProp, removeHtmlTags } from '@/views/calculators/services/OrderService';
import { capitalizeFirstLetter } from '@/views/calculators/services/UiStepService';
import { isDevelop } from './environmentService';
import { trackError } from './trackingUtility';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { CommonModel, ContactInformationCommon, PersonInfoCommon } from '../types/CommonModel';
import {
	getCalculatorNameUi,
	getFormerInsuranceCompanies,
	getFormerInsuranceCompanyIds,
	getShortProductName,
} from './ProductService';
import { hasDependentSplashProduct } from './campaignService';
import { CustomerCalculation } from '@/store/modules/calculatorContext';

const axiosService = new AxiosService();

interface QuoteSalesForce {
	policy_uuid?: string;
	policy_number?: string;
	description: string;
	spd_product_name?: string;
	product_name: string;
	product_version: string;
	price: number;
}

interface ProductDescriptionSalesForce {
	productName: string;
	status: string;
	description?: {
		personInfo;
		campaign;
		calculation;
		carInfo?;
		dogInfo?;
		familyInfo?;
	};
}

interface CalculationSalesForce {
	monthYear: string;
	coverages: string[];
	packageName: string;
	pKLevel: string;
	statutoryFee: number;
	basePrice: number;
	totalPrice: number;
	yearlyStatutoryFee: number;
	yearlyBasePrice: number;
	yearlyPriceTotal: number;
	riskId?: number;
	segmentedProductProperty?: string;
	insuredValueAmount?: number;
}

interface CampaignSalesForce {
	id: string;
	description: string;
	campaignSpecific?: string;
}

interface ConsentSalesForce {
	key: string;
	start_date: string;
	// companies?: string[];
	// companyIds?: string[];
	competitors?: string[];
}

export const sendToSalesForce = async (
	yearlyPrice: number,
	productNames: string,
	description: string,
	product: CalculatorUiComponent
): Promise<any> => {
	description += addProp('<br/><br/>version', store.state.uiVersion);
	description += addProp('<br/>transactionId', store.state.calculatorContext.transactionId); // transactionId has to be last value in description
	// DEBUG description += addProp('<br/><br/>userAgent', window?.navigator?.userAgent);

	const headline = `${yearlyPrice} ${productNames}\n`;
	const descriptionForSalesForce = headline + removeHtmlTags(description);

	let res;
	if (product.cms.sendProposalToSalesForce) {
		res = await postToSalesForce(product, descriptionForSalesForce);
	}

	if (product.cms.useSalesForceQuoteApi) {
		res = await postToSalesForceQuoteApi(product, descriptionForSalesForce);
	}
	return res;
};

/***** private methods *****************/
const postToSalesForce = async (product: CalculatorUiComponent, description: string): Promise<any> => {
	// DEBUG https://preprod-developer.almbrand.dk/marketing-leads#
	// DEBUG console.log('postToSalesForce product model', product.model);

	const { personInfo, contactInformation, streetNumber, streetLetter, phone, firstName, lastName } =
		personContactInfo(product);

	let leadSource = capitalizeFirstLetter(store.getters.getThemeContext ? store.getters.getThemeContext : 'Alm Brand');
	if (leadSource.toLowerCase() === 'almbrand') {
		leadSource = 'Alm Brand';
	}
	const businessBrand = leadSource.replaceAll(' ', '');
	const subLeadSource = leadSource.toLowerCase().replaceAll(' ', '') + '.dk - Online beregner';

	const body = {
		leads: [
			{
				description,
				uri: 'asdasd/aa',
				lead_source: leadSource,
				lead_contact: {
					email: contactInformation.email,
					mobilephone: phone,
					phone,
				},
				sub_lead_source: subLeadSource,
				line_of_business: 'Forsikring',
				segment: 'Privat',
				business_brand: businessBrand,
				lead_name: {
					firstname: firstName,
					lastname: lastName,
				},
				phone: phone,
				lead_address: {
					city: personInfo.zipName || 'København Ø', // ensure lead will always be generated
					floor: personInfo.floorNr,
					floor_door: personInfo.doorNr,
					postal_code: personInfo.zipCode || '2100', // ensure lead will always be generated
					street: personInfo.streetName,
					street_letter: streetLetter,
					street_number: streetNumber,
				},
			},
		],
	};

	let res;
	try {
		let testEvnUri = '';
		if (isDevelop) {
			testEvnUri = product.cms.salesForceTestUri;
		}
		res = await axiosService.post(
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/marketing/leads' + testEvnUri,
			body
		);
		return res;
	} catch (e) {
		trackError('postToSalesForce', res?.status + '');
		exception(e);
	}
};

const postToSalesForceQuoteApi = async (
	product: CalculatorUiComponent,
	descriptionForSalesForce: string
): Promise<any> => {
	let res;

	try {
		const { personInfo, contactInformation, streetNumber, streetLetter, phone, firstName, lastName } =
			personContactInfo(product);

		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if (customerCalculation.hasCustomerData) {
			if (!contactInformation.cpr && customerCalculation.customer.personInfo?.cprNo) {
				contactInformation.cpr = customerCalculation.customer.personInfo.cprNo;
			}
		}
		const body = {
			customer_details: {
				validated_user: !!contactInformation.customerNo,
				first_name: firstName,
				last_name: lastName,
				social_security_number: contactInformation.cpr || 'NA',
				customer_number: contactInformation.customerNo,
				address: {
					street_name: personInfo.streetName,
					street_number: streetNumber,
					street_code: personInfo.streetCode,
					street_letter: streetLetter,
					postal_code: parseInt(personInfo.zipCode),
					city: personInfo.zipName,
					county: 'DK',
					kvhx: personInfo.kvhxValue,
				},
				email: contactInformation.email,
				mobile: phone,
				business_brand: '080796cf-3289-45a2-a1b2-5447e45fa099',
			},
			opportunity_details: { description: descriptionForSalesForce, json_description: undefined },

			quotes: [], // WAIT for SalesForce
			consents: [],
		};

		const basket = store.getters.getSelectableGroup('basket');
		const isBasket = basket.length > 1;
		const opportunityDetails = [];
		basket.forEach((uniqueProductName: string) => {
			const model = store.getters.getCalculator(uniqueProductName)?.model;
			// WAIT for SalesForce - body.quotes.push(getProductQuote(model, uniqueProductName, isBasket));
			opportunityDetails.push(getProductDescription(model, uniqueProductName, isBasket));
		});
		body.opportunity_details.json_description = JSON.stringify({ products: opportunityDetails });

		// DEBUG console.log('opportunityDetails', opportunityDetails);

		if (product.cms.useConsentTermination && product.model.contact_information.accept) {
			const consent: ConsentSalesForce = {
				key: 'onlinetermination_private',
				start_date: new Date().toISOString(),
			};
			const formerCompanies = getFormerInsuranceCompanies();

			if (formerCompanies.length > 0) {
				// DEBUG consent.companies = formerCompanies;
				// DEBUG consent.companyIds = getFormerInsuranceCompanyIds(consent.companies, product.cms);
				consent.competitors = getFormerInsuranceCompanyIds(formerCompanies, product.cms);
			}
			// DEBUG console.log('companies', formerCompanies);
			// DEBUG console.log('companyIds', consent.competitors);
			body.consents.push(consent);
		}

		// test
		if (isDevelop) {
			const headerValue = product.cms.salesForceTestEnvironmentHeader;
			if (headerValue) {
				axiosService.customHeaders.push({ name: 'environment', value: headerValue });
			}
		}

		res = await axiosService.post(
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/quote-calculator/quote/opportunity/create',
			body
		);
		return res;
	} catch (e) {
		trackError('postToSalesForceQuoteApi', res?.status + '');
		exception(e);
	} finally {
		axiosService.customHeaders = [];
	}
};

// WAIT for SalesForce
const getProductQuote = (model: CommonModel, productName: string, isBasket: boolean): QuoteSalesForce => {
	let price = -1;
	let description = getShortProductName(productName) + ', ikke beregnet';

	const productReady = isCalculated(model, isBasket);

	if (productReady) {
		price =
			model.choosePackage.monthYear === 'M'
				? model.choosePackage.selectedPackage.totalPrice
				: model.choosePackage.selectedPackage.yearlyPriceTotal;
		description = getShortProductName(productName) + ', ' + model.choosePackage.selectedPackage.name;

		if (model.campaign.description) {
			description += `, Kampagne: ${model.campaign.description}`;
		}

		if (model.campaign.splash) {
			if (store.state.campaignSplashDependentActive && !hasDependentSplashProduct()) {
				// DEBUG console.log('ignoring splash', model.campaign.splash);
			} else {
				description += `, Kampagne specifik: ${model.campaign.splash}`;
			}
		}
	}

	return {
		policy_uuid: 'e93faf77-9e0b-4361-9d2b-9e797ea911a8', //'NA', //
		policy_number: '208920650', //'NA', //
		description,
		// DEBUG spd_product_name: 'PV.CODAN.AGENT', //'NA',
		product_name: 'PV.CODAN.AGENT',
		product_version: 'PV.CODAN.AGENT',
		price,
	};
};

const getProductDescription = (
	model: CommonModel,
	productName: string,
	isBasket: boolean
): ProductDescriptionSalesForce => {
	const productReady = isCalculated(model, isBasket);
	const productNameUi = getCalculatorNameUi(productName);
	if (!productReady) {
		return {
			productName: productNameUi,
			status: 'Produkt ikke beregnet',
		};
	}

	const productDescription: ProductDescriptionSalesForce = {
		productName: productNameUi,
		status: 'Ok',
		description: {
			personInfo: { ...(model as any).personInfo },
			campaign: mapCampaign(model),
			calculation: mapCalculationData(model),
		},
	};

	const anyModel: any = model;
	if (anyModel?.carInfo) {
		productDescription.description.carInfo = { ...anyModel.carInfo };
	} else if (anyModel?.dogInfo) {
		productDescription.description.dogInfo = { ...anyModel.dogInfo };
	} else if (anyModel?.familyInfo) {
		productDescription.description.familyInfo = { ...anyModel.familyInfo };
	}
	return productDescription;
};

const isCalculated = (model: CommonModel, isBasket: boolean): boolean => {
	let calculated = false;
	if (isBasket && model?.readyForBasket) {
		calculated = true;
	}

	if (!calculated) {
		calculated = model?.choosePackage?.selectedPackage?.totalPrice > 0;
	}
	return calculated;
};

const mapCalculationData = (model: CommonModel): CalculationSalesForce => {
	if (model?.choosePackage?.selectedPackage?.basePrice) {
		const choosePackage = model.choosePackage;
		// DEBUG console.log('choosePackage', choosePackage);

		const pack = model.choosePackage.selectedPackage;
		const mapped: CalculationSalesForce = {
			monthYear: choosePackage.monthYear,
			coverages: pack.expandedCoverages,
			packageName: pack.name,
			pKLevel: pack.currentPlusCostumerLevel,
			statutoryFee: pack.statutoryFee,
			basePrice: pack.basePrice,
			totalPrice: pack.totalPrice,
			yearlyStatutoryFee: pack.yearlyStatutoryFee,
			yearlyBasePrice: pack.yearlyBasePrice,
			yearlyPriceTotal: pack.yearlyPriceTotal,
		};

		if (choosePackage.ownRiskId !== -1) {
			mapped.riskId = choosePackage.ownRiskId;
		}
		if (choosePackage.segmentedProductProperty) {
			mapped.segmentedProductProperty = choosePackage.segmentedProductProperty;
		}
		if (choosePackage.insuredValueAmount) {
			mapped.insuredValueAmount = parseInt(choosePackage.insuredValueAmount + ''); // insure number
		}
		return mapped;
	}
	return undefined;
};

const mapCampaign = (model: CommonModel): CampaignSalesForce => {
	if (model?.choosePackage?.selectedPackage?.basePrice) {
		const campaign = model.campaign;

		const mapped: CampaignSalesForce = {
			id: campaign.ID,
			description: campaign.description,
		};

		if (campaign.splash) {
			if (store.state.campaignSplashDependentActive && !hasDependentSplashProduct()) {
				// nothing - campaign dependency unresolved
			} else {
				mapped.campaignSpecific = campaign.splash;
			}
		}

		return mapped;
	}
	return undefined;
};

const personContactInfo = (product: CalculatorUiComponent) => {
	const personInfo: PersonInfoCommon = product.model.personInfo;
	const contactInformation: ContactInformationCommon = product.model.contact_information;
	const streetNumber = personInfo.houseNr?.trim().replace(/\D/g, '');
	let streetLetter = personInfo.houseNr?.trim().replace(/[^a-z]+/i, '');
	if (streetLetter === '') {
		streetLetter = undefined;
	}

	const phone = contactInformation.phone?.replaceAll(' ', '').replaceAll('+45', '');
	const nameArray = contactInformation.name.trim().split(' ');
	let firstName = nameArray[0];
	const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : 'NA';
	if (lastName !== 'NA') {
		firstName = contactInformation.name.replaceAll(' ' + lastName, '');
	}

	return { personInfo, contactInformation, streetNumber, streetLetter, phone, firstName, lastName };
};
