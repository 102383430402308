import { Vue, Options } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

@Options({
	name: 'usabillaInPageComponent',
	props: {
		model: Object as PropType<UsabillaInPageBlock>,
		headlineClasses: String,
	},
})
export default class UsabillaInPageComponent extends Vue {
	model: UsabillaInPageBlock;
	headlineClasses: string;

	private static waiting = false;
	beforeCreate() {
		store.dispatch('setUsabillaCount');
	}

	mounted() {
		// tell store that one usabilla div is mounted
		// there are being loaded 2 times usabilla script from GTM, so on pageLoad, there will always be double usabilla iFrames!

		store.dispatch('setUsabillaMounted');
		UsabillaInPageComponent.reloadUsabillas(this);
	}

	public static reloadUsabillas(usabillaInPageComponent?: UsabillaInPageComponent) {
		// no usabilla component present
		if (!usabillaInPageComponent) {
			return;
		}
		// check if this is the last mounted usabilla div
		const usabilla = store.getters.getUsabillaState;
		const site = usabillaInPageComponent.model?.siteId ? usabillaInPageComponent.model.siteId : 'e9e9f96ce675';
		// this.clearIFrames();

		// DEBUG console.log('calling reloadUsabillas', usabilla.mounted, usabilla.count, (window as any).usabilla !== undefined);
		if (usabilla.mounted === usabilla.count && (window as any).usabilla) {
			if (UsabillaInPageComponent.waiting) {
				// DEBUG console.log('skipping usabillas');
				return;
			}
			UsabillaInPageComponent.waiting = true;

			// KLK if there are more than one type of usabilla on a page, look at the actual usabilla div's and search for extra iFrames.

			const iFrames = document.querySelectorAll('[ub-in-page] iframe') as any;

			if (!iFrames || iFrames.length === 0) {
				(window as any).usabilla.load('w.usabilla.com', site);
			}
			let maxRun = 8;
			const interval = setInterval(() => {
				if (maxRun < 1) {
					clearInterval(interval);
				}
				this.clearIFrames();
				maxRun--;
				// DEBUG console.log('running ', maxRun);
			}, 250);
			UsabillaInPageComponent.waiting = false;
		}
	}
	private static clearIFrames() {
		const iFrames = document.querySelectorAll('[ub-in-page] iframe') as any;
		// DEBUG console.log('usabillas', iFrames);
		if (iFrames && iFrames.length > 1 && (window as any).usabilla) {
			// more than one iFrame
			iFrames.forEach((element, inx) => {
				if (inx > 0) {
					element.parentNode.removeChild(element);
				}
			});
		}
	}
}
