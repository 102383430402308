import BuyCar from './BuyCarComponent';
import AxiosService from '@/views/calculators/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	checkFirstPackagePrices,
	finishCalculation,
	handleSpecialCarDiscounts,
	mapCoveragesFromProductConfig,
	prepareCalculations,
	setPrices,
	setUpExcesses,
	setupCalculation,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { hasAddressSupplement } from '../services/ValidationService';
import { modalWithExit, togglePopup } from '../services/AlertService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import { PlusCustomerType, updateExcessesFromCalculation } from '../services/ProductService';
import { CarSettings } from './CarSettings';
import { CarModel } from './CarModel';
import { exception, information } from '@/appinsights/logging/ApplicationInsightLog';
import { Calculator } from '../BuyInsuranceComponent';

export default class CarCalculator implements Calculator {
	public model: CarModel; // the data structure for state and properties
	public cms: CarSettings; // settings from EPI
	private readonly licensePlateSearchUrl: string;
	public calcConfig: any;
	// used for first three calculations, to see if there's known error codes
	public successCalc = true;
	public axiosService: AxiosService;
	private readonly calculatorUrl: string;
	public calculatorInfo: Array<any>;
	private trackCalcError = true;
	private trackSpecialCarError = true;
	private latestCall = 0;

	constructor(buyCar: BuyCar) {
		this.model = buyCar.model;
		this.cms = buyCar.cms;
		this.calculatorInfo = JSON.parse(buyCar.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/car/quotes';

		this.licensePlateSearchUrl =
			defaultConfig.baseUrl +
			store.state.openServiceCalculatorUrl +
			// 'test' + // add this to use hotfix for going through alpha in Prod ( Hotfix not deployed yet to production in Justice league )
			'/insurance/car/variants/by-registration-number/';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		setupPackages(this.model, this.cms, this.calcConfig);

		if (!this.model.carInfo.yearlyMileage) {
			this.model.carInfo.yearlyMileage = this.cms.kmForbrug[2]; // op til 15.000 km
		}

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model, this.calcConfig.excessList);
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			setupCalculation(this);

			// FuelType/VehicleGroup discount
			const special = handleSpecialCarDiscounts(this.model, this.cms);

			const promises = [];
			this.model.calculation.abCalc.packageIds.forEach((packageId) => {
				promises.push(this.onePackageCalcEpi(monthly, excessId, packageId, special.specialDiscount));
			});

			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			exception(ex);
			if (monthly) {
				this.errorPopUp();
			}
			return Promise.resolve(false);
		}
	}

	public async searchLicensePlate(value) {
		this.latestCall++;
		const myCall = this.latestCall;
		await new Promise((res) => setTimeout(res, this.cms.licensePlateSearchDelay));
		if (myCall === this.latestCall) {
			const url = `${this.licensePlateSearchUrl}${encodeURI(value)}`;
			return await this.axiosService.get(url);
		} else {
			return { data: { ignore: true } };
		}
	}
	public async getCalculations(excessId?: number): Promise<boolean> {
		const selectedExcessId = this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;
		prepareCalculations(this.model, this.cms, true, selectedExcessId);
		return this.getEpiCalculations(selectedExcessId);
	}

	private async getEpiCalculations(selectedExcessId: number): Promise<boolean> {
		// reset calculation
		this.model.choosePackage.useTiaOffer = false;
		this.setUpConfig();
		let success = true;

		if (hasAddressSupplement(this.model, this.cms)) {
			this.model.calculation.addressSupplement = this.cms.addressSupplement;
		} else {
			this.model.calculation.addressSupplement = 1;
		}

		success = await this.calculatePackagesEpi(true, selectedExcessId);
		// check if has got prices
		if (!success) {
			success = checkFirstPackagePrices(this.model, selectedExcessId);
		}

		if (success) {
			// get yearlyPrices
			this.calculatePackagesEpi(false, selectedExcessId);
		}

		finishCalculation(this.model, success);
		return success;
	}

	public async onePackageCalcEpi(
		monthly: boolean,
		excessId: number,
		packageId: number,
		specialDiscount: number
	): Promise<boolean> {
		if (this.isSpecialCarOrExcludedBrand()) {
			this.successCalc = false;
			await this.specialCarModal();
			return Promise.resolve(false);
		}
		const response = await this.axiosService.getRetryDefaultUrl(
			this.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
		);

		// use this if calculator hangs - const response = { status: 500 } as any;
		if (response?.status !== 200) {
			if (isDevelop && this.cms.mockData) {
				information('MOCK DATA');
				const add = parseInt(500000 * Math.random() + '');
				response.data = { total: add, detail: { statutory_fee: 15 } };
				response.status = 200;
			} else {
				this.successCalc = false;

				if (response?.quote_problem?.length > 0) {
					return await this.specialCarModal();
				}
				this.errorPopUp();
				return Promise.resolve(false);
			}
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		// mapPriceResult(response); - doesn't work - car is different from other calculators
		this.mapPriceResultForCar(response);
		setPrices(packageId, response, monthly, pack, this.model, this.cms, specialDiscount);
		if (this.cms.updateExcessListFromProductCalculation) {
			updateExcessesFromCalculation(response, this.calcConfig);
			this.setupExcessList();
			this.cms.updateExcessListFromProductCalculation = false;
		}

		updateCalcKeys(this.model);
		return Promise.resolve(true);
	}

	private errorPopUp() {
		let trackToken = 'car calculator';
		if (this.model.carInfo?.subtitle) {
			trackToken += ' ' + this.model.carInfo.subtitle;
		}
		const modal = modalWithExit(this.cms);

		modal.title = this.cms.defaultCalcErrorTitle;
		modal.content = this.cms.defaultCalcErrorContent;
		modal.track = this.trackCalcError;
		modal.id = 'calcError';
		modal.trackToken = trackToken;
		modal.errorType = trackToken;

		if (this.cms.useBtnInlineFail) {
			modal.btnInlineLabel = this.cms.btnInlineFailLabel;
			modal.btnInlineStyle = this.cms.btnInlineFailStyle;
			modal.btnInlineIcon = this.cms.btnInlineIcon;
			modal.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		togglePopup(this.cms, modal);
		this.trackCalcError = false;
	}

	private mapPriceResultForCar(response) {
		const res = response.data;
		response.data.totalPrice = res.total;
		response.data.statutoryFee = res.detail.statutory_fee;
	}

	public createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		// move these outside
		const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.carInfo.carOwnerYears);
		let yearlyDrivingInKm = this.cms.kmForbrug.indexOf(this.model.carInfo.yearlyMileage);
		yearlyDrivingInKm = yearlyDrivingInKm * 5000 + 5000;
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		let groupAgreement = 0;
		if (this.model.campaign.valid) {
			groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
		}
		const pack = this.model.calculation.abCalc.packages.find((pack) => packageId === pack.id);

		// create params
		const params = {
			annual_driving_exceed_in_km: yearlyDrivingInKm,
			claims_last_3_years: 0,
			plus_customer: PlusCustomerType.PK0.toUpperCase(),
			customer_age: customerAge,
			excess_id: excessId,
			years_with_own_car: carOwnerYears,
			postal_code: this.model.personInfo.zipCode,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: pack.calculateRisksCar.toString(),
			kid: this.model.carInfo.useKid ? this.model.carInfo.kid : undefined,
			car_weight: this.model.carInfo.useKid ? this.model.carInfo.carWeight : undefined,
			variant_id: this.model.carInfo.useKid ? undefined : this.model.carInfo.variantId,
			model: this.model.carInfo.useKid ? undefined : this.model.carInfo.model,
			brand: this.model.carInfo.useKid ? undefined : this.model.carInfo.brand,
			group_agreement: groupAgreement > 0 ? groupAgreement : undefined,
			has_gps: this.cms.ignoreGPSRequired ? 'y' : undefined,
			has_immobilizer: this.cms.ignoreGPSRequired ? 'y' : undefined,
		};
		return params;
	}

	private createParamsEpiSpecialCar() {
		const monthlyPrices = 1;
		const packageId = this.model.calculation.abCalc.packageIds[0]; // superkasko
		const excessId = this.model.calculation.abCalc.excessIdDefault;
		const pack = this.model.calculation.abCalc.packages.find((pack) => packageId === pack.id);

		// create params
		const params = {
			years_with_own_car: 2,
			annual_driving_exceed_in_km: 15000,
			customer_age: 30,
			postal_code: 1656,
			plus_customer: PlusCustomerType.PK0.toUpperCase(),
			claims_last_3_years: 0,
			kid: this.model.carInfo.useKid ? this.model.carInfo.kid : undefined,
			car_weight: this.model.carInfo.useKid ? this.model.carInfo.carWeight : undefined,
			variant_id: this.model.carInfo.useKid ? undefined : this.model.carInfo.variantId,
			model: this.model.carInfo.useKid ? undefined : this.model.carInfo.model,
			brand: this.model.carInfo.useKid ? undefined : this.model.carInfo.brand,
			excess_id: excessId,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: pack.calculateRisksCar.toString(),
			has_gps: this.cms.ignoreGPSRequired ? 'y' : undefined,
			has_immobilizer: this.cms.ignoreGPSRequired ? 'y' : undefined,
		};

		return params;
	}

	public async isSpecialCar(): Promise<boolean> {
		if (!this.model.carInfo.checkForSpecialCar) {
			this.model.carInfo.useKid =
				this.cms.useKidFirst &&
				this.model.carInfo.kid !== undefined &&
				this.model.carInfo.carWeight !== undefined;
			this.model.carInfo.checkForSpecialCar = true;

			if (this.isSpecialCarOrExcludedBrand()) {
				return await this.specialCarModal();
			}

			let response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpiSpecialCar());

			if (this.errorSpecialCar(response)) {
				return await this.specialCarModal();
			}

			if (response.status === 400) {
				if (this.possibleFallBackCalculation(response)) {
					// toggle use KID
					this.model.carInfo.useKid = !this.model.carInfo.useKid;
					response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpiSpecialCar());
					if (this.errorSpecialCar(response)) {
						return await this.specialCarModal();
					}
				}
			}

			if (response?.status === 200) {
				if (this.cms.updateExcessListFromProductCalculation) {
					updateExcessesFromCalculation(response, this.calcConfig);
					this.setupExcessList();
					this.cms.updateExcessListFromProductCalculation = false;
				}
			}
		}
		return Promise.resolve(false);
	}

	private possibleFallBackCalculation(response): boolean {
		let possibleFallbackCalculation = false;
		if (!this.model.carInfo.useKid) {
			possibleFallbackCalculation =
				this.model.carInfo.kid !== undefined && this.model.carInfo.carWeight !== undefined;
		} else {
			possibleFallbackCalculation =
				this.model.carInfo.brand && this.model.carInfo.model && this.model.carInfo.variantId;
		}

		return possibleFallbackCalculation && response?.data?.detail?.toLowerCase()?.trim() === 'unknown car variant';
	}

	private errorSpecialCar(response): boolean {
		//indsat 22-03-2024: Godkendt GPS sporingssystem er krævet for at opnå dækning ved tyveri hvis  !this.cms.ignoreGPSRequired
		if (
			response?.status === 400 &&
			(response?.quote_problem?.length > 0 ||
				response?.data?.detail?.toLowerCase().startsWith('antallet af heste') ||
				response?.data?.detail?.toLowerCase().startsWith('godkendt gps'))
		) {
			this.model.carInfo.specialDetected = true;
			return true;
		}

		return false;
	}
	private isSpecialCarOrExcludedBrand() {
		if (
			this.model.carInfo.specialDetected ||
			this.cms.excludedVehicleGroups.includes(this.model.carInfo.vehicleGroup + '')
		) {
			this.model.carInfo.specialDetected = true;
			return true;
		}
		const brand = this.model.carInfo.brand?.toLocaleLowerCase()?.trim();
		return this.cms.carBrandBlackList.includes(brand);
	}

	private async specialCarModal(): Promise<any> {
		const vehicleGroup = this.model.carInfo?.vehicleGroup;
		const brand = this.model.carInfo?.brand?.toLocaleLowerCase()?.trim();
		const model = this.model.carInfo?.model?.toLocaleLowerCase()?.trim();
		let trackMsg = 'customer special car';
		if (brand) {
			trackMsg += ', brand ' + brand;
		}
		if (model) {
			trackMsg += ', model ' + model;
		}
		if (vehicleGroup) {
			trackMsg += ', vehicle group ' + vehicleGroup;
		}

		await new Promise((res) => setTimeout(res, 500));

		const modal = modalWithExit(this.cms);

		modal.title = this.cms.specialCarTitle;
		modal.content = this.cms.specialCarContent;
		modal.track = this.trackSpecialCarError;
		modal.trackToken = `car calculator specialCar`;
		modal.productName = this.model.productName;
		modal.stepName = 'car_info';
		modal.trackMsg = trackMsg;
		modal.errorType = 'info';
		modal.id = 'calcWarning';
		togglePopup(this.cms, modal);

		this.trackSpecialCarError = false;
		return Promise.resolve(true);
	}
}
