import { mapState } from 'vuex';
import ModalComponent from '@/sharedcomponents/baseComponents/ModalComponent/ModalComponent.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from '@vue/runtime-core';
import { trackError, trackInsuranceSelectionNote } from '@/views/calculators/services/trackingUtility';
import { Modal } from '@/views/calculators/services/AlertService';

@Options({
	name: 'ToggleModalComponent',
	components: {
		ModalComponent,
	},
	computed: mapState<any>({
		modal: (state) => store.state.modal,
	}),
})
export default class ToggleModalComponent extends Vue {
	public modal: Modal;

	mounted() {
		store.subscribeAction((action, state) => {
			if (action.type === 'toggleModal') {
				if (!action.payload.isActive) {
					this.modal.show = false;
					// Check if close modal should redirect
					const redirect = this.modal.redirect && this.modal.redirectOnClose;
					if (redirect) {
						store.state.showSpinner = true;
						location.href = this.modal.redirect;
					}
				} else {
					this.track();
				}
			}
		});
	}
	public get showModal() {
		if (!!this.modal.show) {
			// trigger
			nextTick(() => {
				store.dispatch('toggleModal', {
					isActive: this.modal.show,
					id: this.modal.id,
				});
			});
		} else {
			// Hack to Fix scrollbar missing
			document.body.style.overflow = 'initial';
		}
		return !!this.modal.show;
	}

	public closeModal() {
		store.dispatch('toggleModal', { show: false, id: this.modal.id });
	}

	public track() {
		if (this.modal.track) {
			const token = this.modal.trackToken ? this.modal.trackToken : 'error';
			const errorType = this.modal.errorType ? this.modal.errorType : 'NA';
			if (errorType !== 'info') {
				trackError(token, errorType);
			} else {
				trackInsuranceSelectionNote(this.modal.productName, this.modal.stepName, this.modal.trackMsg);
			}
		}
	}

	public getHrefType(): string {
		if (this.modal.redirect && this.modal.redirect !== '') {
			return this.modal.redirect;
		}
		return undefined;
	}

	public getInlineIcon(): string {
		return this.modal.btnInlineIcon;
	}

	public getTarget(): string {
		if (this.modal.btnType === 'link') {
			return '_blank';
		}
		return undefined;
	}

	public handleInlineAction() {
		if (!this.modal.btnInlineAction) {
			return;
		}
		if (typeof this.modal.btnInlineAction == 'function') {
			this.modal.btnInlineAction();
			this.closeModal();
		} else {
			// DEBUG this.closeModal();
			this.$router.push(this.modal.btnInlineAction);
		}
	}
}
