import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { CardBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import { CarSettings } from '../car/CarSettings';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { TravelSettings } from '../travel/TravelSettings';
import { CustomerCalculation, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick, PropType } from 'vue';
import { isApp } from '@/views/calculators/services/environmentService';
import { FamilySettings } from '../family/FamilySettings';
import UpSaleComponent from '../components/UpSaleComponent/UpSaleComponent.vue';

@Options({
	name: 'ReceiptStepComponent',
	components: {
		UpSaleComponent,
		UsabillaInPageComponent,
	},
	computed: mapState<any>({
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
	},
})
export default class ReceiptStepComponent extends Vue {
	card: CardBlock; // ReceiptCard from CMS
	usabillaBlock: UsabillaInPageBlock; // usabillaBlock from CMS

	public cms!:
		| DogSettings
		| TravelSettings
		| CarSettings
		| AccidentSettings
		| HomeSettings
		| HouseSettings
		| FamilySettings; // settings from CMS (store)

	public link: string = null;
	public linkTxt: string = null;
	public componentInit = false;
	public componentShow = false;
	public hasUpsale = false;
	private preHost = '';
	private port = '';

	public async mounted() {
		this.setLink();
		await store.dispatch(RESET_CALCULATORS);
		this.componentInit = true;

		nextTick(() => {
			this.hasUpsale = (this.$refs.upsale as any).suggestedProduct;
		});
		nextTick(() => {
			this.componentShow = true;
		});

		// Fallback make sure receipt is shown no matter upsale
		setTimeout(() => {
			this.componentShow = true;
		}, 1000);
	}

	private setLink() {
		const host = location.host.toLocaleLowerCase();
		if (host.includes('alpha-')) {
			this.preHost = 'alpha-';
		} else if (host.includes('localhost')) {
			if (location.port === '3001') {
				this.port = ':3000';
			} else if (location.port === '40900') {
				this.port = ':40100';
			}
		}
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		// DEBUG https://alpha-mit.almbrand.dk/auth/logout?applicationId=almbrand&redirectUri=https://www.almbrand.dk

		// DERBUG https://alpha-beregn.almbrand.dk/auth/logout?applicationId=almbrand&redirectUri=https://www.almbrand.dk
		if (customerCalculation.hasCustomerData) {
			if (customerCalculation.useLogout) {
				this.link = store.state.logOutUrl;
				this.linkTxt = 'Log ud';
			} else {
				// KLK TODO - App link!
				if (isApp()) {
					// DEBUG this.link = '/m___italmbrand?client=app#/forsikring'
					this.link = `//${this.preHost}mit.almbrand.dk${this.port}/mitalmbrand/forsikring`; // ???????????
				} else {
					// DEBUG this.link = '/m___italmbrand/forsikring';
					this.link = `//${this.preHost}mit.almbrand.dk${this.port}/mitalmbrand/forsikring`;
				}
				this.linkTxt = 'Gå til forsikringer';
			}
		} else {
			// KLK - hosts!
			// DEBUG this.link = '/'; // default - almbrand.dk
			this.link = '//www.almbrand.dk'; // default - almbrand.dk
			this.linkTxt = this.card.linkText;
		}
	}
}
