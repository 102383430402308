import { UPDATE_AUTH } from '@/store/modules/authenticationContext';
import TagsEnum from '@/enums/TagsEnum';
import { CustomerCalculation, DISCOUNT_UPDATE } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { createTestCustomer } from '../testData/customerTest';
import { isDevelop, isWeb, logToUi } from '@/views/calculators/services/environmentService';
import { capitalizeFirstLetter } from './UiStepService';
import AxiosService from '@/views/calculators/services/axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { Validator } from './ValidationService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

const _logProperties = {
	SourceContext: 'LoadCustomer',
	context: 'calculators',
};

const axiosService = new AxiosService();

let loadingPolicies = false;

export const loadCustomer = async (cms: any) => {
	const doLogApp = cms.logToAppUiTest;
	logToUi('LoadCustomer:', doLogApp);
	const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
	try {
		if (!cms.tryLoadCustomer) {
			customerCalculation.hasCustomerData = false;
			return;
		}
		if (customerCalculation.customerFetched) {
			// customer try fetched or already loaded
			return;
		}
		if (!store.state.authenticationContext.isLoaded) {
			await store.dispatch(UPDATE_AUTH);
		}
		if (!store.state.authenticationContext.authenticated) {
			return;
		}
		const customer = await getCustomer();

		if (!customer?.person) {
			return;
		}
		if (customer.tags?.includes(TagsEnum.BUSINESS)) {
			// dont use business customers
			return;
		}
		customerCalculation.customer.contact_information.customerNo = customer.customer_id;
		if (!customerCalculation.customer.contact_information.customerNo) {
			return;
		}

		const phoneNo = getCustomerPhoneNo();
		customerCalculation.customer.contact_information.phone = phoneNo;

		customerCalculation.customer.contact_information.name = `${customer.person.firstname} ${customer.person.lastname}`;

		if (customer.person.cpr_nr) {
			try {
				const customerAge = Validator.getAgeCpr(customer.person.cpr_nr);
				customerCalculation.customer.personInfo.customerAge = customerAge + '';
				customerCalculation.customer.personInfo.cprNo = customer.person.cpr_nr;
			} catch (e) {
				// Too bad
			}
		} else if (isDevelop) {
			// SalesForce doesn't send cpr in test - hardcode here
			try {
				customerCalculation.customer.personInfo.cprNo = '1010680065';
				const customerAge = Validator.getAgeCpr(customerCalculation.customer.personInfo.cprNo);
				customerCalculation.customer.personInfo.customerAge = customerAge + '';
			} catch (e) {
				// Too bad
			}
		}

		// enough data to share
		customerCalculation.hasCustomerData = true;
		// show logout btn at end og flow if not in mitalmbrand
		customerCalculation.useLogout = isWeb();

		// SalesForce doesn't send correct address - missing floor and side, also kvhx is not present
		const details = customer.contact.contact_details;
		let floorNr: string;
		let floorSide: string;
		let floorDoor: string;
		details.forEach((detail) => {
			// DEBUG console.log('key', detail.type);
			// DEBUG console.log('value', detail.detail);
			if (detail.type === 'Email__c' && detail?.detail) {
				customerCalculation.customer.contact_information.email = detail.detail;
			} else if (detail.type === 'Floor__c' && detail?.detail && detail.detail !== 'null') {
				floorNr = detail.detail.toLocaleLowerCase();
			} else if (detail.type === 'Floor_Side_Code__c' && detail?.detail && detail.detail !== 'null') {
				floorSide = detail.detail.toLocaleLowerCase();
			} else if (detail.type === 'Floor_Door__c' && detail?.detail && detail.detail !== 'null') {
				floorDoor = detail.detail.toLocaleLowerCase();
			}
		});

		let addr = customer.contact.address.street_name + ' ' + customer.contact.address.building_no_and_letter + ', ';
		if (floorNr) {
			addr = addr + floorNr + '. ';
		}
		if (floorSide) {
			addr = addr + floorSide + '. ';
		}
		if (floorDoor) {
			addr = addr + floorDoor + '. ';
		}
		addr = addr + customer.contact.address.postal_code + ' ' + customer.contact.address.city_name;

		customerCalculation.customer.personInfo.address = addr;
		customerCalculation.customer.personInfo.zipCode = `${customer.contact.address.postal_code}`;
		customerCalculation.customer.personInfo.zipName = `${customer.contact.address.city_name}`;
		customerCalculation.customer.personInfo.streetName = `${customer.contact.address.street_name}`;
		customerCalculation.customer.personInfo.houseNr = `${customer.contact.address.building_no_and_letter}`;
		customerCalculation.customer.personInfo.floorNr = floorNr;
		if (floorSide) {
			customerCalculation.customer.personInfo.doorNr = floorSide;
		}
		if (floorDoor) {
			if (customerCalculation.customer.personInfo.doorNr) {
				customerCalculation.customer.personInfo.doorNr += ' ' + floorDoor;
			} else {
				customerCalculation.customer.personInfo.doorNr = floorDoor;
			}
		}

		// DEBUG console.log('customer loaded', customerCalculation.customer);

		if (!loadingPolicies) {
			loadingPolicies = true;
		} else {
			// since the policy load is long time running (5+ seconds), wait for previous call to finish updating the store and return
			await waitForPolicies(250, 40);
			return;
		}
		const plusCustomerProducts = await handlePolicies(cms);

		if (plusCustomerProducts?.length > 0) {
			customerCalculation.customer.personInfo.existingAlmBrandProducts = plusCustomerProducts;
		} else {
			customerCalculation.customer.personInfo.existingAlmBrandProducts = [];
		}
		customerCalculation.customer.personInfo.almbrandCustomer = 'ja';
		customerCalculation.agreementsFetched = true;
		// DEBUG console.log('agreements loaded', customerCalculation.customer.personInfo.existingAlmBrandProducts);
	} catch (error) {
		exception(error);
	} finally {
		customerCalculation.customerFetched = true;
	}
};

const handlePolicies = async (cms): Promise<any[]> => {
	const doLogApp = cms.logToAppUiTest;
	try {
		if (cms.useNewPolicyAPI) {
			logToUi('loading new POLICIES!!!!', doLogApp);
			const products = await getPolicies(true);
			logToUi(products, doLogApp);
			const plusCustomerProducts = [];
			if (products?.policies?.length > 0) {
				products.policies.forEach((pol) => {
					const title = capitalizeFirstLetter(pol.title);
					handleDiscountProduct(title, cms, plusCustomerProducts);
				});
			}
			return plusCustomerProducts;
		} else {
			logToUi('loading old POLICIES!!!!', doLogApp);
			const agreementPage = await getPolicies(false);
			logToUi(agreementPage, doLogApp);
			const plusCustomerProducts = [];
			const polices = agreementPage?.agreements || agreementPage?.policies;

			if (polices?.length > 0) {
				polices.forEach((agg) => {
					const title = capitalizeFirstLetter(agg.title);
					handleDiscountProduct(title, cms, plusCustomerProducts);
				});
			}
			return plusCustomerProducts;
		}
	} catch (err) {
		exception(err, 'Fail loading customer products', _logProperties);
	}
};

const handleDiscountProduct = (title, cms, plusCustomerProducts) => {
	if (cms.discountPrimaryProducts.includes(title)) {
		if (!plusCustomerProducts.includes(title)) {
			plusCustomerProducts.push(title);
		}
	}
	if (cms.discountSecondaryProducts.includes(title)) {
		if (!plusCustomerProducts.includes(title)) {
			plusCustomerProducts.push(title);
		}
	}
};

const waitForPolicies = async (intervalMs, triesLeft = 30) => {
	return new Promise((resolve, reject) => {
		const interval = setInterval(async () => {
			if (store.getters.getCustomerCalculation.customerFetched) {
				resolve(true);
				clearInterval(interval);
			} else if (triesLeft <= 1) {
				resolve(true); // Too bad
				clearInterval(interval);
			}
			triesLeft--;
		}, intervalMs);
	});
};

export const handleLoggedInUser = async (comp) => {
	try {
		if (!comp.cms.tryLoadCustomer || comp.model.contact_information.customerNo) {
			// cms blocked for load or customer already loaded
			return;
		}
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if (!customerCalculation.hasCustomerData || !customerCalculation.customerFetched) {
			await new Promise((resolve) => {
				let maxRun = 50; // wait for customer to load 10 seconds
				const interval = setInterval(() => {
					if (customerCalculation.customerFetched || maxRun < 1) {
						clearInterval(interval);
						resolve('Done waiting');
					}
					maxRun--;
				}, 200);
			});
		}

		if (!customerCalculation.hasCustomerData) {
			return;
		}
		if (!comp.model.contact_information.name) {
			comp.model.contact_information.phone = customerCalculation.customer.contact_information.phone;
			comp.model.contact_information.customerNo = customerCalculation.customer.contact_information.customerNo;
			comp.model.contact_information.name = customerCalculation.customer.contact_information.name;
			comp.model.contact_information.email = customerCalculation.customer.contact_information.email;
		}

		if (!comp.model.personInfo.zipCode) {
			comp.model.personInfo.address = customerCalculation.customer.personInfo.address;
			comp.model.personInfo.zipCode = customerCalculation.customer.personInfo.zipCode;
			comp.model.personInfo.zipName = customerCalculation.customer.personInfo.zipName;
			comp.model.personInfo.streetName = customerCalculation.customer.personInfo.streetName;
			comp.model.personInfo.houseNr = customerCalculation.customer.personInfo.houseNr;
			comp.model.personInfo.floorNr = customerCalculation.customer.personInfo.floorNr;
			comp.model.personInfo.doorNr = customerCalculation.customer.personInfo.doorNr;
		}
		if (!comp.model.personInfo.customerAge) {
			if (customerCalculation.customer.personInfo.customerAge) {
				comp.model.personInfo.customerAge = customerCalculation.customer.personInfo.customerAge;
			}
		}

		if (!comp.model.personInfo.almbrandCustomer) {
			if (!customerCalculation.agreementsFetched) {
				// wait for agreements
				new Promise((resolve, reject) => {
					let maxRun = 30;
					const interval = setInterval(() => {
						if (customerCalculation.agreementsFetched || maxRun < 1) {
							if (customerCalculation.agreementsFetched) {
								comp.model.personInfo.almbrandCustomer =
									customerCalculation.customer.personInfo.almbrandCustomer;
								comp.model.personInfo.existingAlmBrandProducts =
									customerCalculation.customer.personInfo.existingAlmBrandProducts;
								if (
									comp.model.personInfo.almbrandCustomer === 'ja' &&
									comp.model.personInfo.existingAlmBrandProducts?.length > 0
								) {
									comp.model.showExistingAlmBrandProducts = false;
									store.dispatch(DISCOUNT_UPDATE);
								}
							}
							clearInterval(interval);
							resolve('Done waiting');
						} else {
							maxRun--;
						}
					}, 150);
				});
			} else {
				comp.model.personInfo.almbrandCustomer = customerCalculation.customer.personInfo.almbrandCustomer;
				comp.model.personInfo.existingAlmBrandProducts =
					customerCalculation.customer.personInfo.existingAlmBrandProducts;
				if (
					comp.model.personInfo.almbrandCustomer === 'ja' &&
					comp.model.personInfo.existingAlmBrandProducts?.length > 0
				) {
					comp.model.showExistingAlmBrandProducts = false;
					store.dispatch(DISCOUNT_UPDATE);
				}
				// KLK if customer is logged in comp.model.showExistingAlmBrandProducts should always be false
			}
		}
	} catch (error) {
		exception(error);
	}
};

/***** private methods *****************/
const getCustomer = async () => {
	try {
		if (store.state.customer) {
			// DEBUG console.log('customer loaded');
			return store.state.customer;
		}
		return await getCustomerViaRest();
	} catch (err) {
		exception(err);
	}
	return undefined;
};

const getCustomerViaRest = async () => {
	// https://mit.almbrand.dk/api/naboo/v1/serviceproxy/customer/details
	const res = await axiosService.get(defaultConfig.baseUrl + store.state.openServiceUrl + '/customer/details');

	if (res.status !== 200) {
		if (isDevelop) {
			await createTestCustomer();
			return store.state.customer;
		}
	}
	store.state.customer = res?.data;
	return store.state.customer;
};

const getPolicies = async (newApi: boolean): Promise<any> => {
	if (store.state.customerProducts) {
		return store.state.customerProducts;
	}
	let url = defaultConfig.baseUrl + store.state.openServiceUrl + '/customer/agreements?page=1';
	if (newApi) {
		url = defaultConfig.baseUrl + store.state.openServiceUrl + '/common/policies/?search=true&page=1&size=50';
	}
	const res = await axiosService.get(url);

	if (res.status === 200) {
		store.state.customerProducts = res.data;
		return store.state.customerProducts;
	}

	return undefined;
};

const getCustomerPhoneNo = (): string => {
	const mobileNo = store.state.customer.contact.contact_details.find((mobile) => {
		return mobile.type.toLowerCase() === 'mobile__c';
	});
	if (mobileNo && mobileNo.detail && mobileNo.detail !== 'null') {
		return mobileNo.detail;
	}

	const phoneNo = store.state.customer.contact.contact_details.find((phone) => {
		return phone.type.toLowerCase() === 'phone';
	});
	if (phoneNo && phoneNo.detail && phoneNo.detail !== 'null') {
		return phoneNo.detail;
	}
	return undefined;
};
