import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { browserIe11OrOlder } from '@/views/calculators/services/environmentService';
import { CALCULATOR_UPDATE_COMMON_MODEL } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { nextTick } from 'vue';
import { togglePopup } from './AlertService';
import { handleLoggedInUser } from './UserLoginService';
import { almbrandOnlineCampaignName, handleCampaigns, upSaleCampaignName } from './campaignService';

// steps names
export const enum STEP {
	DOG_INFO = 'dog_info',
	CAR_INFO = 'car_info',
	CHILDREN_INFO = 'children_info',
	PERSON_INFO = 'person_info',
	PACKAGE = 'choose_package',
	OVERVIEW = 'overview',
	ADDITIONAL_INFO = 'additional_info',
	CONTACT_INFORMATION = 'contact_information',
	PAYMENT = 'payment',
	ORDER = 'order',
}

export const STEPS_EXCLUDE_BASKET: Array<string> = [STEP.CONTACT_INFORMATION, STEP.OVERVIEW, STEP.PAYMENT, STEP.ORDER];

export const initComponent = async (comp): Promise<boolean> => {
	handleQueryParams(comp);
	await handleCampaigns(comp);

	handleHeaderBadge(comp);

	const productsInBasket = store.getters.getSelectableGroup('basket');

	comp.model.multipleProducts = productsInBasket.length > 1;
	if (comp.model.multipleProducts) {
		store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, { fromCommonModel: true });
		removeStepsIfBasket(comp);
	}

	// check if user is logged in (coming from MitAlmbrand), and use customer data
	await handleLoggedInUser(comp);

	removeUnreferencedBlocks(comp);

	if (browserIe11OrOlder()) {
		nextTick().then(() => {
			togglePopup(comp.cms, {
				id: 'error_browser',
				show: true,
				redirect: '/',
				track: true,
				trackToken: 'oldieBrowser',
				title: comp.cms.ieBrowserTitle,
				content: comp.cms.ieBrowserContent,
			});
		});
		let tries = 10;
		const interval = setInterval(() => {
			if (store.state.showSpinner || tries < 0) {
				store.state.showSpinner = false;
				clearInterval(interval);
			}
			tries--;
		}, comp.cms.initialTimeout);
		return false;
	}

	if (comp.cms.closeCalculator) {
		store.state.calculatorContext.calculating = false;
		nextTick().then(() => {
			togglePopup(comp.cms, {
				id: 'closed',
				show: true,
				btnLabel: comp.cms.btnLabelClose,
				btnSecondLabel: comp.cms.btnSecondLabelClose,
				btnSecondStyle: comp.cms.btnSecondLabelCloseStyle,
				redirectOnClose: comp.cms.redirectOnClose,
				redirect: comp.cms.btnRedirectUrlClose,
				track: true,
				trackToken: 'calculator closed',
				title: comp.cms.closeCalculatorPopupTitle,
				content: comp.cms.closeCalculatorPopupContent,
			});
		});
		let tries = 10;
		const interval = setInterval(() => {
			if (store.state.showSpinner && tries < 0) {
				store.state.showSpinner = false;
				clearInterval(interval);
			} else if (!store.state.showSpinner) {
				clearInterval(interval);
			}
			tries--;
		}, comp.cms.initialTimeout);
		return false;
	}

	store.state.showSpinner = false;
	return true;
};

/**
 * Make whole string lowercase and Capitalize 1. Letter
 * If allWords, then take every word to lowercase, split by ' ' and capitalize 1 letter.
 * checks also for double ' '
 * TODO perhaps also check also on '-'
 * @param str
 * @param allWords default = false
 */

export const capitalizeFirstLetter = (str: string, allWordsInString = false) => {
	if (!str || str.trim() === '') {
		return '';
	}

	const str_ = str.toLocaleLowerCase();
	if (!allWordsInString) {
		return str_[0].toUpperCase() + str_.slice(1);
	}
	return str_
		.split(' ')
		.map((w) => {
			w = w.trim();
			return w !== '' ? w[0].toUpperCase() + w.slice(1) : w;
		})
		.join(' ');
};

/***** private methods *****************/

const handleQueryParams = (comp) => {
	try {
		// check for customerNo in query
		const query = comp.$route.query;
		if (Object.keys(query).length === 0) {
			return;
		}
		const customerNo = (query.customerno as string) || (query.customerNo as string) || (query.K as string);
		if (customerNo) {
			comp.model.contact_information.customerNo = customerNo;
		}
		// check for includedProducts
		if (comp.cms.queryProductNames.length > 0) {
			comp.cms.queryProductNames.forEach((name, inx) => {
				const value = query[name];
				if (value !== undefined) {
					if (value === 1 || value.toLocaleLowerCase().trim() === 'true') {
						comp.model.personInfo.almbrandCustomer = 'ja';
						comp.model.personInfo.existingAlmBrandProducts.push(comp.cms.queryProductValues[inx]);
					}
				}
			});
		}
	} catch (error) {
		// too bad
	}
};

const handleHeaderBadge = (comp) => {
	if (!comp.headerBadgeTxt) {
		comp.model.showHeaderBadge = false;
		return;
	}
	if (
		comp.model.campaign.valid &&
		comp.model.campaign.ID &&
		comp.model.campaign.ID !== almbrandOnlineCampaignName &&
		comp.model.campaign.ID !== upSaleCampaignName
	) {
		comp.model.showHeaderBadge = false;
		return;
	}
	comp.model.showHeaderBadge = true;
};

const removeStepsIfBasket = (comp) => {
	if (comp.model.multipleProducts && comp.model.productName !== 'kurv') {
		comp.steps = comp.steps.filter((step) => !STEPS_EXCLUDE_BASKET.includes(step));
	}
	// When STEP.OVERVIEW is not present - routeLink for STEPS:CHOOSE_PACKAGE must be updated
	const choosePackageBlock = comp.contentBlocks.find((block) => block.name === STEP.PACKAGE);
	if (choosePackageBlock) {
		choosePackageBlock.routePath = STEP.ADDITIONAL_INFO;
	}
};
const removeUnreferencedBlocks = (comp) => {
	let removeBlocks = [];
	comp.contentBlocks.forEach((block, inx) => {
		if (comp.model.campaign.valid && block.name === comp.model.campaign.ID) {
			// check for partner welcome page
			comp.model.campaign.hasContentPage = true;
			return;
		}
		if (!comp.steps.includes(block.name)) {
			removeBlocks.push(inx);
			return;
		}
		if (comp.model.multipleProducts || !comp.model.showExistingAlmBrandProducts) {
			// DOG
			if (comp.model.productName === InsurancesProductEnum.HUNDE_FORSIKRING && block.name === STEP.PERSON_INFO) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					// DEBUG comp.cms.defaultCard = STEP.PACKAGE;
					comp.contentBlocks.find((block) => block.name === STEP.DOG_INFO).routePath = STEP.PACKAGE;
				}
			}
			// CHILDREN ACCIDENT
			if (
				comp.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING &&
				block.name === STEP.PERSON_INFO
			) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					comp.contentBlocks.find((block) => block.name === STEP.CHILDREN_INFO).routePath = STEP.PACKAGE;
				}
			}
			// HOUSE
			if (comp.model.productName === InsurancesProductEnum.HUS_FORSIKRING && block.name === STEP.PERSON_INFO) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					comp.cms.defaultCard = STEP.PACKAGE;
					if (comp.model.campaign.valid) {
						const block = comp.contentBlocks.find((block) => block.name === comp.model.campaign.ID);
						if (block) {
							block.routePath = STEP.PACKAGE;
						}
					}
				}
			}
		}
	});

	removeBlocks = removeBlocks.reverse();
	removeBlocks.forEach((inx) => {
		comp.contentBlocks.splice(inx, 1);
	});
};
