import { Options, Vue } from 'vue-class-component';
import { gotoFirstProductInBasket, handlePreselectedProducts } from '../services/NavigationService';
import { exception, warning } from '@/appinsights/logging/ApplicationInsightLog';
@Options({
	name: 'SelectCalculatorPageComponent',
	components: {},
})
export default class SelectCalculatorPageComponent extends Vue {
	// KLK hack, sometimes the url isn't resolved correctly and basket/product not loaded
	private readonly delay = 25;
	private hasRedirected = false;

	async beforeCreate() {
		setTimeout(() => {
			this.resolveUrl();
		}, this.delay);
		// klk hack , sometimes page doesn't load!!
		setTimeout(() => {
			if (!this.hasRedirected) {
				warning('SelectCalculatorPageComponent has not redirected - trying again');
				this.resolveUrl();
			}
		}, this.delay * 200); // 5 seconds
	}

	private async resolveUrl() {
		// got specific product
		if (this.$route.query.product) {
			const productName = this.$route.query.product as string;
			const query = this.$route.query;

			//await store.dispatch('setAppReplaceRoute', true);
			this.$router.replace({
				path: productName,
				force: true,
				query,
				// query: this.$route.query
			});
			this.hasRedirected = true;
			return;
		}
		// check if there is any products defined by queryParams, and put them in the basket
		if (this.$route.query.products) {
			// DEBUG console.log('this.$route.query.products', this.$route.query.products);

			await handlePreselectedProducts(this.$route.query);
			// KLK hack delay
			await new Promise((res) => setTimeout(res, this.delay));
		}
		const insurance = await gotoFirstProductInBasket();
		// DEBUG console.log('Url resolved to', insurance);

		// let url = urlService.ensureCorrectLink(insurance);
		let url = insurance;
		let query = '';
		try {
			const inx = this.$route.fullPath.indexOf('?');
			if (inx > -1) {
				query = this.$route.fullPath.substr(inx, this.$route.fullPath.length);
				url += query;
			}
			// DEBUG console.log('url', url);
		} catch (error) {
			exception(error);
			// too bad
		}
		// await store.dispatch('setAppReplaceRoute', true);
		this.hasRedirected = true;
		// this.$router.replace(url);
		this.$router.replace(url);
	}
}
